.BarraLateral {
  display: flex;
  flex-direction: column;
  background-color: var(--color-primario);
  background-image: linear-gradient(160deg, var(--color-primario) 0%, var(--color-secundario) 100%);
  color: white;
  width: 15rem;
  box-shadow: .15rem 0rem .15rem rgba(0, 0, 0, .15);
  z-index: 2;
  position: sticky;
  top: 0;
  height: 100vh;
}

.BarraLateral__contenedor_logo {
  display: flex;
  align-items: center;
  height: 4.5rem;
  padding: 1rem;
}

.BarraLateral__logo {
  width: 2.5rem;
}

.BarraLateral__nombre_app {
  font-weight: 600;
  font-size: 1.5rem;
  margin-left: .5rem;
  text-shadow: 0 .1rem .2rem rgba(0, 0, 0, .25);
}

.BarraLateral__links {
  display: flex;
  flex-direction: column;
}

.BarraLateral__link {
  padding: 1.1rem .75rem;
  transition: all .25s ease-in;
  border-left: .5rem solid transparent;
  display: flex;
  align-items: center;
  font-size: .8rem;
}

.BarraLateral__link--activo {
  background-color: rgba(0, 0, 0, .25) !important;
  border-left: .5rem solid rgba(2, 194, 242, .7) !important;
}

.BarraLateral__link:hover {
  background-color: rgba(0, 0, 0, .15);
  border-left: .5rem solid #1e93b1;
}

.BarraLateral__icono {
  margin-right: .5rem;
  font-size: 1.25rem;
}