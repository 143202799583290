.SelectorTratante {
  padding: 0.5rem 1.5rem;
  background-color: #f5f5f5;
  cursor: pointer;
  font-size: .9rem;
  margin-bottom: .75rem;
  display: flex;
  align-items: center;
  transition: all .1s ease;
  border-radius: .5rem;
  box-shadow: .05rem .05rem .15rem rgba(0, 0, 0, .15);
}

.SelectorTratante::after,
.SelectorTratante--activo::after {
  font-size: .75rem;
  background-color: rgba(0, 0, 0, .15);
  padding: .25rem .5rem;
  border-radius: 1rem;
  color: white;
  margin-left: auto;
}

.SelectorTratante::after {
  content: 'No autorizado';
}

.SelectorTratante--activo {
  background-color: var(--color-secundario);
  color: white;
  text-shadow: 0 0 .25rem rgba(0, 0, 0, .25);
}

.SelectorTratante--activo::after {
  content: 'Autorizado';
  background-color: rgba(0, 0, 0, .25);
}

.SelectorTratante__checkbox {
  margin-right: 1rem;
  width: .85rem;
  height: .85rem;
}

.SelectorTratante__cargando {
  width: .85rem;
  height: .85rem;
  border: .1rem solid transparent;
  border-right: .1rem solid var(--color-secundario);
  border-bottom: .1rem solid var(--color-secundario);
  border-radius: 100%;
  display: inline-block;
  margin-right: 1rem;
  animation: gira-loader 1s ease infinite both;
}

.SelectorTratante__cargando--blanco {
  border-right: .1rem solid white;
  border-bottom: .1rem solid white;
}

.SelectorTratante__email {
  font-size: .7rem;
  opacity: .7;
  margin-top: .25rem;
}