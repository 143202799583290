.Recuperacion {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primario);
  background-image: linear-gradient(160deg, var(--color-primario) 0%, var(--color-secundario) 100%);
  flex: 1;
}

.Recuperacion__formulario {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: white;
  animation: aparece-registro 1s ease both;
  padding: 1rem;
  width: 30rem;
  border-radius: .25rem;
  box-shadow: 0 .2rem 1rem rgba(0, 0, 0, .25);
}

.Recuperacion__titulo {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}

.Recuperacion__campo {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  width: 100%;
}

.Recuperacion__label {
  margin-bottom: .25rem;
  font-size: .75rem;
}

.Recuperacion__input {
  font-size: 1rem;
  padding: .5rem;
}

.Recuperacion__contenedor_boton {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.Recuperacion__boton {
  background-color: var(--color-primario);
  color: white;
  font-size: .9rem;
  padding: .5rem 2rem;
  border-radius: .5rem;
  box-shadow: 0 .2rem .25rem rgba(0, 0, 0, .25);
  transition: all .25s ease;
  cursor: pointer;
}

.Recuperacion__boton:disabled {
  filter: saturate(50%);
}

.Recuperacion__boton:hover {
  box-shadow: 0 .2rem .5rem rgba(0, 0, 0, .25);
  filter: brightness(115%);
}
