.BarraSuperior {
  height: 3.5rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  z-index: 3;
  border-bottom: 1px solid rgba(0, 0, 0, .15);
}