.ListaPacientes {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.ListaPacientes__titulo {
  font-weight: 600;
  font-size: 1.25rem;
}

.ListaPacientes__tabla {
  width: 100%;
}

.ListaPacientes__encabezados {
  text-transform: uppercase;
  font-weight: bold;
  font-size: .75rem;
  user-select: none;
  border-bottom: 2px solid rgba(0, 0, 0, .3);
}

.ListaPacientes__tabla td,
.ListaPacientes__tabla th {
  text-align: left;
  padding: .75rem .2rem;
}

.ListaPacientes__fila {
  font-size: .9rem;
  border-bottom: 1px solid rgba(0, 0, 0, .3);
}

.ListaPacientes__fila:hover {
  background-color: var(--color-hover);
  cursor: pointer;
}

.ListaPacientes__superior {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
}

.ListaPacientes__boton_agregar_paciente {
  cursor: pointer;
  background: linear-gradient(to bottom, var(--color-primario) 0%, var(--color-secundario) 250%);
  padding: .25rem .75rem;
  border-radius: 1rem;
  color: white;
  transition: all .25s ease;
  box-shadow: 0 0 .15rem rgba(0, 0, 0, .3);
}

.ListaPacientes__boton_agregar_paciente:hover {
  filter: brightness(110%);
  box-shadow: 0 0 .25rem rgba(0, 0, 0, .3);
}

.ListaPacientes__icono_agregar_paciente {
  margin-right: .25rem;
}