.Plataforma {
  display: flex;
  flex: 1;
}

.Plataforma__contenedor {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #f2f2f2;
}

.Plataforma__centro {
  padding: 1rem;
  margin: 1rem;
  background-color: white;
  flex: 1;
  border-radius: 1rem;
  box-shadow: 0 0 .25rem rgba(0, 0, 0, .05);
}