.EditarCuenta {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  flex: 1;
}

.EditarCuenta__superior {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 1rem;
}

.EditarCuenta__titulo {
  font-weight: 600;
  font-size: 1.15rem;
}

.EditarCuenta__contenedor {
  display: flex;
}

.EditarCuenta__formulario {
  display: flex;
  flex-direction: column;
}

.EditarCuenta__formulario_titulo {
  margin-bottom: 1rem;
}

.EditarCuenta__campo {
  margin-bottom: .75rem;
}

.EditarCuenta__label {
  margin-bottom: .25rem;
  font-size: .8rem;
}

.EditarCuenta__input {
  padding: .5rem .75rem;
  font-size: .9rem;
  border-radius: .5rem;
  border: 1px solid #4d4d4d;
  outline: none;
  transition: all .25s ease;
  width: 24rem;
}

.EditarCuenta__input:focus {
  border-color: var(--color-primario);
  box-shadow: 0 0 .15rem var(--color-primario);
}

.EditarCuenta__boton {
  background-color: var(--color-primario);
  color: white;
  font-weight: 600;
  border-radius: 2rem;
  padding: .75rem 3rem;
  cursor: pointer;
  box-shadow: 0 0 .15rem rgba(0, 0, 0, .25);
  outline: none;
  transition: all .25s ease;
  align-self: flex-start;
  filter: none;
  margin-top: 1rem;
}

.EditarCuenta__boton:disabled {
  filter: brightness(150%) saturate(20%);
  box-shadow: none;
  cursor: default;
}

.EditarCuenta__boton:not(:disabled):hover {
  box-shadow: 0 0 .35rem rgba(0, 0, 0, .5);
  filter: brightness(110%);
}

.EditarCuenta__lista_tratantes {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  user-select: none;
}