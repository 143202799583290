.Estadisticas {
  padding: 1rem;
}

.Estadisticas__titulo {
  font-weight: 600;
  font-size: 1.35rem;
}

.Estadisticas__contenedor {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
}

.Estadisticas__contenedor_grafico {
  width: 31%;
  margin-top: 1.2rem;
  padding: 1rem;
  box-shadow: 0.1rem .1rem .35rem rgba(0,0,0,0.1);
  border-radius: 10%;
}

.Estadisticas__promedio {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Estadisticas__numero {
  font-size: 1rem;
}

.Estadisticas__descripcion {
  font-size: .6rem;
}

.Estadisticas__mensaje {
  display: flex;
  margin-top: 2rem;
}

.Estadisticas__mensaje_label {
  text-align: center;
  color: var(--color-primario);
  font-weight: bold;
}