.Inicio {
  display: flex;
  flex-direction: column;
}

.Inicio__video {
  width: 100%;
  height: calc(100vh - 11rem);
}

.Inicio__manual {
  color: white;
  background: var(--color-primario);
  padding: .35rem 1rem;
  border-radius: .5rem;
  font-size: .9rem;
  align-self: flex-start;
  margin-bottom: 1rem;
}

.Inicio__manual:hover {
  filter: brightness(115%);
}