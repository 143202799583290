.ListaTratantes {
  padding: 1rem;
}

.ListaTratantes__cargando {
  width: 4rem;
  height: 4rem;
  border: .5em solid transparent;
  border-bottom: .5em solid var(--color-secundario);
  border-right: .5em solid var(--color-secundario);
  border-radius: 100%;
  animation: gira-loader 1s ease infinite both;
  margin-top: 4rem;
}

.ListaTratantes__superior {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 1rem;
}

.ListaTratantes__titulo {
  font-weight: 600;
  font-size: 1.15rem;
}

.ListaTratantes__explicacion {
  margin-bottom: 1rem;
  line-height: 1.5;
  font-size: .8rem;
}

.ListaTratantes__buscador {
  padding: .25rem 1rem;
  border-radius: 1rem;
  outline: none;
  border: 1px solid var(--color-primario);
}

.ListaTratantes__buscador::placeholder {
  opacity: .5;
}

.ListaTratantes__contenedor_busqueda {
  position: relative;
}

.ListaTratantes__icono_busqueda {
  position: absolute;
  right: .5rem;
  top: .35rem;
  font-size: 1.15rem;
  opacity: .5;
  cursor: pointer;
}

.ListaTratantes__lista {
  max-height: 60vh;
  overflow: auto;
}

@keyframes gira-loader {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}