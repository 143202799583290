.Login {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primario);
  background-image: linear-gradient(160deg, var(--color-primario) 0%, #80D0C7 100%);
  position: relative; 
}

.Login__contenedor_logo {
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
}

.Login__logo {
  width: 4rem;
}

.Login__nombre_app {
  font-weight: 600;
  font-size: 1.75rem;
  margin-left: .5rem;
  color: var(--color-primario);
}

.Login__formulario {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 1rem 1.5rem;
  border-radius: .25rem;
  box-shadow: 0 .2rem 1rem rgba(0, 0, 0, .25);
  transition: all .75s ease;
}

.Login__campo {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  width: 20rem;
}

.Login__label {
  margin-bottom: .25rem;
  font-size: .9rem;
}

.Login__input {
  font-size: 1rem;
  padding: .5rem;
}

.Login__boton {
  background-color: var(--color-primario);
  color: white;
  font-size: 1rem;
  padding: .5rem 2rem;
  border-radius: .5rem;
  box-shadow: 0 .2rem .25rem rgba(0, 0, 0, .25);
  transition: all .25s ease;
  cursor: pointer;
}

.Login__boton:hover {
  box-shadow: 0 .2rem .5rem rgba(0, 0, 0, .25);
  filter: brightness(115%);
}

.Login__boton:disabled {
  filter: saturate(50%);
  cursor: progress;
}

.Login__links {
  padding-top: 1.75rem;
  font-size: .85rem;
  align-self: stretch;
  justify-content: space-between;
  display: flex;
}

.Login__link--destacado {
  background-color: var(--color-error);
  color: white;
  padding: .25rem 1rem;
  border-radius: .25rem;
}

.Login__link:hover {
  color: #8e8e8e;
  cursor: pointer;
}

.Login__error {
  padding-top: 1rem;
  font-size: .8rem;
  max-width: 30ch;
  text-align: center;
  color: var(--color-error);
  line-height: 1.25;
}

.Login__formulario--saliendo {
  transform: translateX(-2rem);
  opacity: 0;
}
