.PopupMenuUsuario__fondo {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.PopupMenuUsuario {
  position: absolute;
  opacity: 0;
  z-index: 3;
  background-color: white;
  top: 3rem;
  right: .5rem;
  transition: all .25s ease;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 .25rem rgba(0, 0, 0, .15);
  border-radius: 1rem;
  pointer-events: none;
}

.PopupMenuUsuario--activo {
  opacity: 1;
  pointer-events: all;
}

.PopupMenuUsuario_link {
  padding: 1rem 1.25rem;
  font-weight: 600;
}

.PopupMenuUsuario_link:hover {
  background-color: #f5f5f5;
  border-radius: 1rem;
}