.Historial {
  padding: 1rem;
}

.Historial__superior {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  align-items: center;
}

.Historial__titulo {
  font-weight: 600;
  font-size: 1.35rem;
  background-color: white;
}

.Historial__botones {
  display: flex;
}

.Historial__icono {
  margin-right: .5rem;
}

.Historial__boton {
  background: var(--color-primario);
  color: white;
  height: 1.5rem;
  padding: .25rem 1rem;
  display: flex;
  align-items: center;
  border-radius: .75rem;
  cursor: pointer;
}

.Historial__boton:first-child {
  margin-right: 1rem;
}

.Historial__tabla {
  font-size: .75rem;
  width: 100%;
}

.Historial__tabla_encabezados {
  text-align: left;
  font-size: .7rem;
  text-transform: uppercase;
  font-weight: 600;
  border-bottom: 1px solid #2a383c;
}

.Historial__contenedor_tabla {
  max-height: calc(100vh - 14rem);
  overflow: auto;
}

.Historial__contenedor_tabla::-webkit-scrollbar {
  width: .6rem;
  background-color: transparent;
}

.Historial__contenedor_tabla::-webkit-scrollbar-thumb {
  background-color: var(--color-secundario);
  border-radius: .25rem;
}

.Historial__tabla_encabezados thead {
  position: sticky;
  top: 0rem;
}

.Historial__tabla_encabezados th {
  padding: .5rem 1rem .5rem 0;
  cursor: default;
  vertical-align: middle;
  background-color: white;
  position: sticky;
  top: 0rem;
}

.Historial__tabla_encabezados th[title] {
  cursor: help;
}

.Historial__tabla_fila {
  border-bottom: 1px solid #bacace;
}

.Historial__tabla_fila:hover {
  background-color: #f3f6f7;
}

.Historial__tabla_fila td {
  padding: 1rem 1rem 1rem 0;
  text-align: left;
  cursor: default;
}

.Historial__tabla_encabezados th:first-child,
.Historial__tabla_fila td:first-child {
  padding-left: .5rem;
  line-height: 1.15;
}

.Historial .Historial__tabla_celda_centrada {
  text-align: center;
}
