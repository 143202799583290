.TOS {
  position: absolute;
  z-index: 2;
  background-color: rgba(0, 0, 0, .35);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: aparecen-tos .25s ease both;
}

.TOS__contenedor {
  display: flex;
  flex-direction: column;
}

.TOS__titulo {
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;
}

.TOS__texto {
  line-height: 1.25;
  text-align: center;
  font-size: .8rem;
}

.TOS__tarjeta {
  background-color: white;
  border-radius: .25rem;
  width: 30vw;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
}

.TOS__boton_cerrar {
  background-color: var(--color-primario);
  height: 2rem;
  border-radius: .5rem;
  color: white;
  margin-top: 1rem;
  cursor: pointer;
  font-size: 1rem;
}

@keyframes aparecen-tos {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}