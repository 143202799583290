.Exportar {
  padding: 1rem;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; 
}

.Exportar__titulo {
  font-weight: 600;
  font-size: 1.35rem;
}

.Exportar__contenedor {
  width: 400px;
}

.Exportar__formulario {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
}

.Exportar__campo {
  margin-bottom: .75rem;
  display: flex;
  align-items: center;
}

.Exportar__label {
  font-size: .8rem;
  margin-right: 1rem;
}

.Exportar__input {
  padding: .75rem;
  transition: all .25s ease;
  cursor: pointer;
}

.Exportar__boton {
  background-color: var(--color-primario);
  color: white;
  font-weight: 600;
  border-radius: 2rem;
  padding: .75rem 3rem;
  cursor: pointer;
  box-shadow: 0 0 .15rem rgba(0, 0, 0, .25);
  outline: none;
  transition: all .25s ease;
  margin-top: .75rem;
  align-self: flex-start;
}

.Exportar__boton:hover {
  box-shadow: 0 0 .35rem rgba(0, 0, 0, .5);
  filter: brightness(110%);
}