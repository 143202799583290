.RegistroPaciente {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primario);
  background-image: linear-gradient(160deg, var(--color-primario) 0%, var(--color-secundario) 100%);
  flex: 1;
}

.RegistroPaciente--incrustado {
  background: none;
}

.RegistroPaciente__formulario {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: white;
  animation: aparece-registro 1s ease both;
  padding: 1rem;
  width: 30rem;
  border-radius: .25rem;
  box-shadow: 0 .2rem 1rem rgba(0, 0, 0, .25);
}

.RegistroPaciente__titulo {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}

.RegistroPaciente__campo {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  width: 100%;
}
.RegistroPaciente__campo[campo="fechaNacimiento"] {
  width: 40%;
  margin-right: 1rem;
}

.RegistroPaciente__campo[campo="genero"] {
  width: calc(60% - 1rem);
}

.RegistroPaciente__campo[campo="password"] {
  width: 50%;
  padding-right: .5rem;
}

.RegistroPaciente__campo[campo="confirmacion-password"] {
  width: 50%;
  padding-left: .5rem;
}

.RegistroPaciente__label {
  margin-bottom: .25rem;
  font-size: .75rem;
}

.RegistroPaciente__input {
  font-size: .9rem;
  height: 2rem;
  padding: 0 .5rem;
}

.RegistroPaciente__inferior {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.RegistroPaciente__tos {
  font-size: .8rem;
  padding-right: 2rem;
  line-height: 1.15;
}

.RegistroPaciente__link_tos {
  text-decoration: underline;
  color: var(--color-primario);
  cursor: pointer;
}

.RegistroPaciente__boton {
  background-color: var(--color-primario);
  color: white;
  font-size: .9rem;
  padding: .5rem 2rem;
  border-radius: .5rem;
  box-shadow: 0 .2rem .25rem rgba(0, 0, 0, .25);
  transition: all .25s ease;
  cursor: pointer;
}

.RegistroPaciente__boton:hover {
  box-shadow: 0 .2rem .5rem rgba(0, 0, 0, .25);
  filter: brightness(115%);
}

.RegistroPaciente__error {
  font-size: .7rem;
  padding-top: .25rem;
  color: #A61246;
}

.RegistroPaciente__boton:disabled {
  filter: saturate(10%) brightness(150%);
  box-shadow: none;
  cursor: not-allowed;
}

@keyframes aparece-registro {
  0% {
    transform: translateX(2rem);
    opacity: 0;
  }
  100% {
    transform: translateX(0rem);
    opacity: 1;
  }
}

@media (orientation: portrait) {
  .RegistroPaciente__formulario {
    width: 100vw;
  }
}