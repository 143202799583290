.MenuUsuario {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-right: 1rem;
  position: relative;
}

.MenuUsuario__nombre {
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 4;
}

.MenuUsuario__identificacion {
  display: flex;
  flex-direction: column;
  font-size: .9rem;
}

.MenuUsuario__rol {
  font-size: .75rem;
  opacity: .8;
  padding-top: .15rem;
}

.MenuUsuario__avatar {
  background-color: #D93C18;
  width: 2.15rem;
  height: 2.15rem;
  font-size: 1.15rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: .75rem;
  color: white;
}

.MenuUsuario__boton_menu {
  font-size: 1.25rem;
  margin-left: .5rem;
}
